<template>
    <div
        v-if="showAlert"
        class="banner banner-activate"
        data-testid="account-activation-banner-container"
    >
        <div class="d-md-flex align-items-center justify-content-around banner-container my-1 mx-auto">
            <div>
                <h5
                    class="banner-heading fw-bold mb-1"
                    data-testid="account-activation-banner-title"
                >
                    {{ title }}
                </h5>
                <p data-testid="account-activation-banner-description">
                    {{ overrideDescription ?? description }}
                </p>
            </div>
            <div
                class="w-100 d-grid ml-1"
                v-if="cta"
            >
                <a
                    :class="disableButton ? '!tw-pointer-events-none !tw-bg-gray-600' : ''"
                    class="btn btn-activate"
                    @click.prevent="onClickCta"
                    @keydown.prevent="onClickCta"
                    data-testid="account-activation-banner-primary-button"
                >
                    {{ cta }}
                </a>
                <a
                    v-if="secondaryCta"
                    class="btn btn-tertiary border-0"
                    @click.prevent="onSecondaryClickCta"
                    @keydown.prevent="onSecondaryClickCta"
                    data-testid="account-activation-banner-secondary-button"
                >
                    {{ secondaryCta }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { i18n } from '@/utils/i18n'
    import { AvenCardActivationStatus, CardActivationError } from '@/store/overviewStore.types'
    import { AvenAccountStanding } from 'aven_types'
    import { useOverviewStore } from '@/store/overviewStore'
    import { useDeviceInfoStore } from '@/store/deviceInfoStore'
    import { RouteNames, RoutePaths } from '@/routes/router.types'
    import format, { toFormattedUSDStripTrailingZeroCents } from '@/mixins/format'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { startNewCashOutWithCleanSlate } from '@/utils/cashOutUtils'
    import { navigationTabRouteNames } from '@/utils/navigationTabs'
    import { ActivationRequirementStatus } from 'aven_types'
    import { logger } from '@/utils/logger'
    import { useIsUccProduct } from '@/utils/uccComposables'
    import { useUpgradeOffersStore } from '@/store/upgradeOffersStore'
    import { EXPERIMENT_SPEC_GROUP_NAME, EXPERIMENT_TYPE, isExperimentEnabled } from '@/experiments/getOverrideForExperiment'
    import { isSmallOrMediumScreenFn } from '@/mixins/deviceMixin'
    import { logEvent } from '@/services/http-client'

    export default {
        name: 'AccountActivationBanner',
        mixins: [format],
        data: function () {
            return {
                overrideDescription: null,
                disableButton: false,
                isMloAvailable: false,
            }
        },
        async mounted() {
            this.isMloAvailable = await useUpgradeOffersStore().getMloAvailability()

            if (this.isUccUpgradeToHomeAndMloAvailable) {
                logEvent('view_ucc_activation_banner_home_upgrade_mlo_available_cta')
            }
        },
        computed: {
            returnToken() {
                return useOverviewStore().returnToken
            },
            isWebView() {
                return useDeviceInfoStore().isWebView
            },
            isSingleWebView() {
                return useDeviceInfoStore().isSingleWebView
            },
            cardRequiresActivation() {
                return useOverviewStore().cardRequiresActivation
            },
            cardActivationStatus() {
                return useOverviewStore().cardActivationStatus
            },
            activationRequirementStatus() {
                return useOverviewStore().activationRequirementStatus
            },
            cashOutUpfrontTransferAmountWithoutFee() {
                return useOverviewStore().firstDrawAmountExcludingFee
            },
            shouldStartUpfrontCashOutFlow() {
                return useOverviewStore().shouldStartUpfrontCashOutFlow
            },
            shouldShowUpfrontCashOutInProgress() {
                return useOverviewStore().shouldShowUpfrontCashOutInProgress
            },
            pendingUpfrontCashOutForCoOwnerCard() {
                return useOverviewStore().pendingUpfrontCashOutForCoOwnerCard
            },
            mustWaitForPriorLienRelease() {
                return useOverviewStore().blockingContingencyError === CardActivationError.ERROR_BLOCKED_BY_PRIOR_LIEN_RELEASE
            },
            mustCompleteUccFlowFirst() {
                return useOverviewStore().blockingContingencyError === CardActivationError.ERROR_BLOCKED_BY_UCC_INCOMPLETE_FLOW
            },
            isUccUpgradeToHomeAndMloAvailable() {
                return (
                    this.isMloAvailable &&
                    useIsUccProduct().isUccProduct &&
                    useUpgradeOffersStore().upgradeOfferToDisplay &&
                    isExperimentEnabled(EXPERIMENT_TYPE.uccUpgrade, EXPERIMENT_SPEC_GROUP_NAME.uccMloActivationBanner)
                )
            },
            isIncomeVerified() {
                return useOverviewStore().isIncomeVerified
            },
            hasAttemptedIdentityChecks() {
                return useOverviewStore().hasAttemptedIdentityChecks
            },
            avenAccountStanding() {
                return useOverviewStore().avenAccountStanding
            },
            fastFundingLineSizeIfApproved() {
                return useOverviewStore().fastFunding.lineSizeIfApproved
            },
            isFastFundingEligible() {
                return useOverviewStore().fastFunding.isEligible
            },
            isNaturalDisasterVideoAndLocationReviewPending() {
                return useOverviewStore().naturalDisasterAreaVideoAndLocation.status !== ActivationRequirementStatus.passed
            },
            showAlert: function () {
                // account status banner take precedence over activation banner
                if (this.avenAccountStanding !== AvenAccountStanding.current) {
                    return false
                }

                const showOnRoute = navigationTabRouteNames.includes(this.$route.name)
                // my.aven.com has already fetched card activation status by the time Dashboard component loads after login completes.
                // mobile app login is part of native code. accountOverview api is not executed until dashboard.vue loads.
                // isCardActivated remains in unknown state until accountOverview completes.
                // we do not want activation banner to appear while isCardActivated is in unknown state.
                const cardActivationStatusInitialized = useOverviewStore().cardActivationStatus !== AvenCardActivationStatus.unknown
                const result = cardActivationStatusInitialized && showOnRoute && this.cardRequiresActivation

                this.$emit('show', 'AccountActivationBanner', result ? 'show' : 'hide')
                return result
            },
            title: function () {
                if (this.mustCompleteUccFlowFirst) {
                    return i18n.t(`components.accountActivationBanner.incompleteUccFlow.title`)
                }

                if (this.isNaturalDisasterVideoAndLocationReviewPending) {
                    return i18n.t(`components.accountActivationBanner.incompleteNaturalDisasterVideoAndLocationReview.title`)
                }

                if (this.mustWaitForPriorLienRelease) {
                    return i18n.t(`components.accountActivationBanner.priorLienRelease.title`)
                }

                if (this.shouldStartUpfrontCashOutFlow) {
                    return i18n.t(`components.accountActivationBanner.cashOutUpfrontNow.title`)
                }

                if (this.pendingUpfrontCashOutForCoOwnerCard) {
                    return i18n.t(`components.accountActivationBanner.pendingCashOutUpfrontCoOwner.title`)
                }

                if (this.shouldShowUpfrontCashOutInProgress) {
                    return i18n.t(`components.accountActivationBanner.cashOutUpfrontInProgress.title`)
                }

                // If they aren't fast funding eligible, show regular activation language
                if (!this.isFastFundingEligible) {
                    return i18n.t(`components.accountActivationBanner.activate.title`)
                }

                // If they submitted fast funding but got denied, pretend fast-funding never existed
                return i18n.t(`components.accountActivationBanner.activate.title`)
            },
            description: function () {
                if (this.mustCompleteUccFlowFirst) {
                    if (this.isIncomeVerified && this.hasAttemptedIdentityChecks) {
                        logger.error('ucc activation contingency active but applicant has both verified income and attempted identity checks')
                    }
                    if (this.isIncomeVerified) {
                        return i18n.t(`components.accountActivationBanner.incompleteUccFlow.descriptionIdentity`)
                    } else if (this.hasAttemptedIdentityChecks) {
                        return i18n.t(`components.accountActivationBanner.incompleteUccFlow.descriptionIncome`)
                    }
                    return i18n.t(`components.accountActivationBanner.incompleteUccFlow.descriptionIdentityAndIncome`)
                }

                if (this.isNaturalDisasterVideoAndLocationReviewPending) {
                    return i18n.t(`components.accountActivationBanner.incompleteNaturalDisasterVideoAndLocationReview.description`)
                }

                if (this.mustWaitForPriorLienRelease) {
                    return i18n.t(`components.accountActivationBanner.priorLienRelease.description`)
                }

                if (this.shouldStartUpfrontCashOutFlow) {
                    return i18n.t(`components.accountActivationBanner.cashOutUpfrontNow.description`, {
                        cashOutUpfrontAmount: toFormattedUSDStripTrailingZeroCents(this.cashOutUpfrontTransferAmountWithoutFee),
                    })
                }

                if (this.pendingUpfrontCashOutForCoOwnerCard) {
                    return i18n.t(`components.accountActivationBanner.pendingCashOutUpfrontCoOwner.description`)
                }

                if (this.shouldShowUpfrontCashOutInProgress) {
                    return i18n.t(`components.accountActivationBanner.cashOutUpfrontInProgress.description`)
                }

                // If they submitted fast funding but got denied, pretend fast-funding never existed
                // If they got approved for fast funding but the fast funding approved amount is equal to their total credit limit, also show a normal message
                return i18n.t(`components.accountActivationBanner.activate.description`)
            },
            cta: function () {
                if (this.mustCompleteUccFlowFirst) {
                    return i18n.t(`components.accountActivationBanner.incompleteUccFlow.cta`)
                }

                if (this.isNaturalDisasterVideoAndLocationReviewPending) {
                    return i18n.t(`components.accountActivationBanner.incompleteNaturalDisasterVideoAndLocationReview.cta`)
                }

                if (this.mustWaitForPriorLienRelease) {
                    return i18n.t(`components.accountActivationBanner.priorLienRelease.cta`)
                }

                if (this.shouldStartUpfrontCashOutFlow) {
                    return i18n.t(`components.accountActivationBanner.cashOutUpfrontNow.cta`)
                }

                if (this.pendingUpfrontCashOutForCoOwnerCard) {
                    return ''
                }

                if (this.shouldShowUpfrontCashOutInProgress) {
                    return ''
                }

                if (this.isUccUpgradeToHomeAndMloAvailable) {
                    if (isSmallOrMediumScreenFn()) {
                        return i18n.t(`components.accountActivationBanner.uccActivate.cta`)
                    } else {
                        return i18n.t(`components.accountActivationBanner.uccActivate.ctaDesktop`)
                    }
                }

                return i18n.t('components.accountActivationBanner.activate.cta')
            },
            secondaryCta: function () {
                if (this.shouldStartUpfrontCashOutFlow) {
                    return i18n.t(`components.accountActivationBanner.cashOutUpfrontNow.secondaryCta`)
                }

                if (this.isUccUpgradeToHomeAndMloAvailable && !this.mustCompleteUccFlowFirst) {
                    return i18n.t(`components.accountActivationBanner.uccActivate.secondaryCta`)
                }

                return null
            },
        },
        methods: {
            onClickCta: async function () {
                if (this.mustCompleteUccFlowFirst) {
                    const jodlLink = `${process.env.VUE_APP_AVEN_URL}/a/${this.returnToken}`

                    this.$logEvent('click_continue_ucc_application_button')
                    return (window.location.href = jodlLink)
                }

                if (this.isNaturalDisasterVideoAndLocationReviewPending) {
                    this.$logEvent('click_continue_video_and_location_review')
                    return await this.$router.push({
                        name: RouteNames.ACTIVATION_REQUIRES_VIDEO_AND_LOCATION,
                    })
                }

                if (this.mustWaitForPriorLienRelease) {
                    this.$logEvent('click_prior_lien_release_info_button')
                    if (this.isWebView && !this.isSingleWebView) {
                        return (window.location.href = `${RoutePaths.CARD_ACTIVATION_CONTINGENCY_MORE_DETAILS}?cardActivationError=${CardActivationError.ERROR_BLOCKED_BY_PRIOR_LIEN_RELEASE}`)
                    } else {
                        return this.$router.push({
                            name: RouteNames.CARD_ACTIVATION_CONTINGENCY_MORE_DETAILS,
                            params: {
                                cardActivationError: CardActivationError.ERROR_BLOCKED_BY_PRIOR_LIEN_RELEASE,
                            },
                        })
                    }
                }

                if (this.shouldStartUpfrontCashOutFlow) {
                    // reset to start over a new Cash Out
                    startNewCashOutWithCleanSlate()
                    // set the transaction amount for Cash Out Upfront
                    appSessionStorage.setItem(localStorageKey.cashOutAmount, String(this.cashOutUpfrontTransferAmountWithoutFee))
                    appSessionStorage.setItem(localStorageKey.preActivationCashOutFlow, String(true))
                    this.$logEvent('click_cash_out_upfront_now')
                    if (this.isWebView && !this.isSingleWebView) {
                        return (window.location.href = RoutePaths.CASH_OUT_PAYMENT_OPTIONS)
                    } else {
                        return this.$router.push({
                            name: RouteNames.CASH_OUT_PAYMENT_OPTIONS,
                        })
                    }
                }

                if (this.isUccUpgradeToHomeAndMloAvailable && this.isMloAvailable) {
                    if (isSmallOrMediumScreenFn()) {
                        this.$logEvent('click_banner_ucc_activate_phone')
                        return (window.location.href = 'tel:1-833-998-5545')
                    } else {
                        this.$logEvent('click_banner_ucc_activate_callback')
                        await useUpgradeOffersStore().sendCallbackRequest()
                        this.overrideDescription = 'All set! Expect a call from Ryan within the next minute. If you don’t receive the call, please reach out to (833) 998-5545.'
                        this.disableButton = true
                        return
                    }
                }

                switch (useOverviewStore().blockingContingencyError) {
                    case CardActivationError.ERROR_IN_RESCISSION_PERIOD:
                    case CardActivationError.ERROR_BLOCKED_BY_TITLE_INSURANCE:
                    case CardActivationError.ERROR_BLOCKED_BY_HAZARD_INSURANCE:
                    case CardActivationError.ERROR_BLOCKED_BY_EMPLOYMENT_VERIFICATION:
                    case CardActivationError.ERROR_BLOCKED_BY_MLS_LISTING:
                    case CardActivationError.ERROR_BLOCKED_BY_PRIOR_LIEN_RELEASE:
                    case CardActivationError.ERROR_BLOCKED_BY_PENDING_CONTINGENCIES:
                    case CardActivationError.ERROR_PRIMARY_CARD_NOT_ACTIVATED:
                        if (this.isWebView && !this.isSingleWebView) {
                            return (window.location.href = `${RoutePaths.CARD_ACTIVATION_CONTINGENCY}?cardActivationError=${useOverviewStore().blockingContingencyError}`)
                        } else {
                            return await this.$router.push({
                                name: RouteNames.CARD_ACTIVATION_CONTINGENCY,
                                params: { cardActivationError: useOverviewStore().blockingContingencyError },
                            })
                        }
                }

                this.navigateToCardActivation()
            },
            onSecondaryClickCta: async function () {
                if (this.shouldStartUpfrontCashOutFlow) {
                    this.$logEvent('click_banner_cash_out_upfront_bt_instead')
                    await this.$router.push({ name: RouteNames.CASH_OUT_UP_FRONT_BT_INSTEAD })
                }

                if (this.isUccUpgradeToHomeAndMloAvailable && !this.mustCompleteUccFlowFirst) {
                    this.$logEvent('click_banner_ucc_activate_online')
                    this.navigateToCardActivation()
                }
            },
            navigateToCardActivation: function () {
                if (this.isWebView && !this.isSingleWebView) {
                    return (window.location.href = RoutePaths.CARD_ACTIVATION)
                } else {
                    return this.$router.push({
                        name: RouteNames.CARD_ACTIVATION,
                    })
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/banner';
    @import 'node_modules/aven_shared/src/styles/elements/button';

    .banner {
        max-width: 640px;
        margin: 0;
    }
</style>
